import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import LeadsTable from "../../components/leads-table";
import useFetchData from "../../components/use-fetch-data";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AdminLayout from "../../components/admin-layout";
import BrokerMultiStepForm from "../../components/broker-multi-step-form";
import AddBrokerSection from "../../components/add-broker-section";
import BrokerDetailsSection from "../../components/broker-details-section";
import BrokerLayout from "../../components/broker-layout";
import BrokerProfile from "../../components/broker-profile";
import withRoleBasedRedirect from "../../components/with-role-based-redirects";
import { useAuth0 } from "@auth0/auth0-react";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import bgImg from "../../images/bg-elements.svg";
import { Spinner } from "react-bootstrap";

const LoadingPage = () => {
	return (
		<div
			style={{
				background: "linear-gradient(138deg, #29b9f2 2.82%, #0433bf 97.42%)",
			}}
			className="position-relative"
		>
			<div
				style={{ top: "50vh" }}
				className="position-absolute start-50 translate-middle"
			>
				<Spinner
					style={{ width: "6rem", height: "6rem" }}
					animation="border"
					role="status"
					variant="white"
				>
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			</div>
			<img
				className=" "
				src={bgImg}
				alt=""
				style={{
					width: "100%",
				}}
			/>
			<GatsbySeo title="Loading" language="en" noindex nofollow />
		</div>
	);
};

export default LoadingPage;
